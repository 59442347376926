<template>
    <div>
        <div class="commen-hader">
            <div class="container">
                <div class="row">
                    <div class="col-sm-12">
                        <h2 v-if="profileCompletionMode">Complete Your Profile</h2>
                        <h2 v-else>REGISTRATION</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="register-section">
            <div class="row">
                <div class="col-sm-6">
                    <div class="regist-left">
                        <div class="container">
                            <div class="row">
                                <div class="col-sm-4"></div>
                                <div class="col-sm-8">
                                    <div class="registinner">
                                        <img src="/images/logo-transprint.png" />
                                        <p>
                                            If you have an account already?<a href="#">
                                                So Login Now.</a
                                            >
                                            And start less than a minute.
                                        </p>
                                        <img src="/images/border-or.png" class="border-or" />
                                        <span>OR</span>
                                        <h3>LOGIN VIA SOCIAL ACCOUNT</h3>
                                        <a href="#" class="login-facebook"
                                            ><i class="fa fa-facebook-f"></i> Login with Facebook</a
                                        >
                                        <a
                                            href="#"
                                            class="login-facebook"
                                            style="background-color: red;"
                                            ><i class="fa fa-google-plus"></i> Login with Google</a
                                        >
                                        <a href="#" class="login-facebook"
                                            ><i class="fa fa-linkedin"></i> Login with Linkedin</a
                                        >
                                        <!-- <a href="#" class="login-facebook twtter"><i class="fa fa-twitter-square"></i> Login with Twitter</a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="regist-right">
                        <div class="container">
                            <div v-if="profileCompletionMode">
                                <div class="col-sm-9">
                                    <div class="regist-righbox">
                                        <VerifyEmail />
                                    </div>
                                </div>
                            </div>
                            <div class="row" v-else>
                                <div class="col-sm-9">
                                    <div class="regist-righbox">
                                        <h3>CREATE AN ACCOUNT</h3>
                                        <p>
                                            Fill out the required information below.
                                        </p>
                                        <form>
                                            <label class="input-icon"
                                                ><i class="fa fa-user"></i
                                            ></label>
                                            <input
                                                type="type"
                                                name="name"
                                                placeholder="Name of Company"
                                                class="styleinput"
                                                v-model="name"
                                                required
                                            />
                                            <div class="status small" id="name-status"></div>
                                            <br />
                                            <label class="input-icon"
                                                ><i class="fa fa-envelope"></i
                                            ></label>
                                            <input
                                                type="type"
                                                name="email"
                                                placeholder="Email Address"
                                                class="styleinput"
                                                v-model="email"
                                                required
                                            />

                                            <div class="status small" id="email-status"></div>
                                            <!-- 
                                            <div v-if="ress && ress.email" class="text-danger">
                                                {{ ress.email[0] }}
                                            </div> -->

                                            <br />
                                            <label class="input-icon"
                                                ><i class="fa fa-unlock-alt"></i
                                            ></label>
                                            <input
                                                :type="showPassword ? 'text' : 'password'"
                                                name="password"
                                                placeholder="Password"
                                                class=" styleinput"
                                                v-model="password"
                                                required
                                            />
                                            <div
                                                class="small text-primary"
                                                @click="showPassword = !showPassword"
                                                style="cursor: pointer"
                                            >
                                                {{
                                                    showPassword ? "Hide Password" : "Show Password"
                                                }}
                                            </div>
                                            <div class="status small" id="password-status"></div>
                                            <br />
                                            <!-- <label class="input-icon"
                                                ><i class="fa fa-unlock-alt"></i
                                            ></label> -->
                                            <!-- <input
                                                type="password"
                                                name="password_confirmation"
                                                placeholder="Re-enter Password"
                                                class="styleinput"
                                                v-model="password_confirmation"
                                                required
                                            /> -->
                                            <!-- <div
                                                class="status small"
                                                id="password_confirmation-status"
                                            ></div>
                                            <div v-if="ress && ress.password" class="text-danger">
                                                {{ ress.password[0] }}
                                            </div> -->
                                            <!-- <br /> -->
                                            <label class="input-icon"
                                                ><i class="fa fa-phone"></i
                                            ></label>
                                            <input
                                                type="type"
                                                name="mobile"
                                                placeholder="Contact Number"
                                                class="styleinput"
                                                v-model="mobile"
                                                required
                                            />
                                            <div class="status small" id="mobile-status"></div>
                                            <!-- <div v-if="ress && ress.mobile" class="text-danger">
                                                {{ ress.mobile[0] }}
                                            </div> -->
                                            <br />
                                            <label class="input-icon"
                                                ><i class="fa fa-briefcase"></i
                                            ></label>
                                            <input
                                                type="type"
                                                name="business"
                                                placeholder="Nature of Business"
                                                class="styleinput"
                                                v-model="business"
                                                required
                                            />
                                            <div class="status small" id="business-status"></div>
                                            <br />
                                            <label class="input-icon"
                                                ><i class="fa fa-briefcase"></i
                                            ></label>
                                            <input
                                                type="type"
                                                name="activities"
                                                placeholder="Business Activities"
                                                class="styleinput"
                                                v-model="activities"
                                                required
                                            />
                                            <div class="status small" id="activities-status"></div>
                                            <br />
                                            <label class="input-icon"
                                                ><i class="fa fa-map-marker"></i
                                            ></label>
                                            <input
                                                type="type"
                                                name="address"
                                                placeholder="Address"
                                                class="styleinput"
                                                v-model="address"
                                                required
                                            />
                                            <div class="status small" id="address-status"></div>
                                            <br />

                                            <select
                                                class="reg-sel-1"
                                                name="state"
                                                v-model="state"
                                                v-on:change="getcity"
                                                required
                                            >
                                                <option disabled value="">State</option>
                                                <option
                                                    v-for="user in category"
                                                    :key="user.state_code"
                                                    :value="user.state_code"
                                                    >{{ user.state_name }}</option
                                                >
                                            </select>

                                            <!--<v-select-->
                                            <!--  v-model="state"-->
                                            <!--  :options="category"-->
                                            <!--  @input="getcity"-->
                                            <!--  placeholder="State"-->
                                            <!--required >-->
                                            <!--</v-select>-->

                                            <div class="status small" id="state-status"></div>

                                            <!--<v-select-->
                                            <!--  v-model="city"-->
                                            <!--  :options="cities"-->
                                            <!--  placeholder="City"-->
                                            <!--  required >-->
                                            <!--</v-select>-->

                                            <select name="city" v-model="city" required>
                                                <option disabled value="">City</option>
                                                <option
                                                    v-for="(user, index) in cities"
                                                    :key="index"
                                                    :value="user.city_code"
                                                    >{{ user.city_name }}</option
                                                >
                                            </select>

                                            <div class="status small" id="city-status"></div>
                                            <br />
                                            <br />
                                            <label class="input-icon"
                                                ><i class="fa fa-map-marker"></i
                                            ></label>
                                            <input
                                                type="type"
                                                name="pincode"
                                                placeholder="Pincode"
                                                class="styleinput"
                                                v-model="pincode"
                                                required
                                            />
                                            <div class="status small" id="pincode-status"></div>
                                            <br />
                                            <label>
                                                <input
                                                    type="radio"
                                                    name="agree"
                                                    value="1"
                                                    v-model="agree"
                                                    required
                                                />
                                                By creating account you agree<br />
                                                to our
                                                <a href="#">Terms & Conditions</a>
                                            </label>
                                            <div class="status small" id="agree-status"></div>
                                            <button
                                                type="submit"
                                                class="registerbtn"
                                                @click="register"
                                            >
                                                CREATE NOW
                                            </button>
                                        </form>
                                    </div>
                                </div>
                                <div class="col-sm-3"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import "vue-select/src/scss/vue-select.scss"
import vSelect from "vue-select"
import VerifyEmail from "./auth/VerifyEmail.vue"

import { mapState, mapActions } from "vuex"
import axios from "axios"
import { HTTP } from "../_helper/http-constants"
import User from "../apis/User"
import scrollTop from "./utils/scrollTop"
import request from "../apis/request"

export default {
    name: "register",
    components: {
        // vSelect,
        VerifyEmail,
    },

    data() {
        return {
            ress: {},
            cities: [],
            category: [],
            name: "",
            email: "",
            password: "",
            password_confirmation: "",
            profileCompletionMode: false,
            showPassword: false,
            mobile: "",
            business: "",
            activities: "",
            address: "",
            state: "",
            city: "",
            pincode: "",
            agree: "",
            submitAttempted: false,

            action: "/webregister",
        }
    },
    mounted() {
        scrollTop()
        // const user = localStorage.getItem("user")
        // const isLoggedIn = !!localStorage.getItem("auth")

        const completedProfile = this.user && this.authenticated && !!this.user.profile_completed

        console.log(this.user, this.authenticated, completedProfile)

        if (this.user && !completedProfile) {
            // show the email verification component
            this.profileCompletionMode = true
        }
    },
    computed: {
        // user() {
        //     const user = localStorage.getItem("user")
        //     return user
        // },
        ...mapState("auth", {
            user: "user",
            authenticated: "authenticated",
        }),
    },
    watch: {
        name(e) {
            this.updateValidation()
        },
        email(e) {
            this.updateValidation()
        },
        password(e) {
            this.updateValidation()
        },
        mobile(e) {
            this.updateValidation()
        },
        business(e) {
            this.updateValidation()
        },
        activities(e) {
            this.updateValidation()
        },
        address(e) {
            this.updateValidation()
        },
        state(e) {
            this.updateValidation()
        },
        city(e) {
            this.updateValidation()
        },
        pincode(e) {
            this.updateValidation()
        },
        agree(e) {
            this.updateValidation()
        },
    },
    methods: {
        ...mapActions("auth", {
            login: "login",
            fetchUser: "fetchUser",
        }),
        updateValidation() {
            if (!this.submitAttempted) return
            console.log("update validation running")

            if (this.name.length == 0) {
                document.getElementById("name-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("name-status").innerHTML = ""
            }

            if (this.password.length < 6) {
                document.getElementById("password-status").innerHTML =
                    "<span class='warning' style='color: red;'>Password must be minimum 6 characters.</span>"
            } else {
                document.getElementById("password-status").innerHTML = ""
            }

            if (this.business.length == 0) {
                document.getElementById("business-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("business-status").innerHTML = ""
            }
            if (this.activities.length == 0) {
                document.getElementById("activities-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("activities-status").innerHTML = ""
            }
            if (this.address.length == 0) {
                document.getElementById("address-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("address-status").innerHTML = ""
            }
            if (this.state.length == 0) {
                document.getElementById("state-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("state-status").innerHTML = ""
            }
            if (this.city.length == 0) {
                document.getElementById("city-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("city-status").innerHTML = ""
            }
            if (this.pincode.length == 0) {
                document.getElementById("pincode-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("pincode-status").innerHTML = ""
            }
            if (this.agree.length == 0) {
                document.getElementById("agree-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("agree-status").innerHTML = ""
            }

            for (let error in this.ress) {
                const id = error + "-status"
                const msg = this.ress[error][0]
                const el = document.getElementById(id)
                el.innerHTML = `<span class='warning' style='color: red;'>${msg}</span>`
                console.log(id, "error", msg, el)
            }

            if (Object.values(this.ress).length > 0) {
                return
            }

            if (this.email.length == 0) {
                document.getElementById("email-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                document.getElementById("email-status").innerHTML = ""
            }

            // if (this.password_confirmation.length == 0) {
            //     document.getElementById("password_confirmation-status").innerHTML =
            //         "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            // } else {
            //     document.getElementById("password_confirmation-status").innerHTML = ""
            // }
            if (this.mobile.length == 0) {
                document.getElementById("mobile-status").innerHTML =
                    "<span class='warning' style='color: red;'>You need to fill out this form.</span>"
            } else {
                console.log("removing mobile message", this.ress)
                document.getElementById("mobile-status").innerHTML = ""
            }
        },
        register: function(e) {
            e.preventDefault()
            this.submitAttempted = true
            console.log("REGISTER PART IS RUNNING", this.ress)
            this.updateValidation()
            if (
                !(
                    this.name.length > 0 &&
                    this.password.length >= 6 &&
                    // this.password_confirmation.length > 0 &&
                    this.business.length > 0 &&
                    this.activities.length > 0 &&
                    this.address.length > 0 &&
                    this.state.length > 0 &&
                    this.city.length > 0 &&
                    this.pincode.length > 0 &&
                    this.validateEmail() &&
                    this.validatemobile()
                )
            ) {
                this.$toasted.error("Please correct the errors before submitting the form")
                return
            }
            request
                .post(this.action, {
                    name: this.name,
                    email: this.email,
                    password: this.password,
                    // password_confirmation: this.password_confirmation,
                    mobile: this.mobile,
                    business: this.business,
                    activities: this.activities,
                    address: this.address,
                    state: this.state,
                    city: this.city,
                    pincode: this.pincode,
                })
                .then((res) => {
                    console.log(res)
                    this.ress = {}
                    this.$toasted.success("Registered successfully", {
                        keepOnHover: true,
                        iconPack: "fontawesome",
                        icon: "check",
                        theme: "toasted-primary",
                        // timerProgressBar: true,
                        duration: 3000,
                    })

                    // login user after successful registration...

                    this.login({ email: this.email, password: this.password })
                        .then(async (res) => {
                            // await this.fetchUser()
                            // this.$router.push({ name: "dashboard" })
                            window.location.reload()
                        })
                        .catch(() => {
                            console.log("error in logging in user")
                        })

                    // this.$router.push({ name: "login" })
                })
                .catch((err) => {
                    //alert('err');
                    console.log("some erorr occured in logging in ", err, err.response)
                    this.$toasted.error("Please correct the errors before submitting the form")
                    if (err.response && err.response.status === 422) {
                        this.ress = err.response.data.errors || {}
                    }
                    console.log("CATCH PART IS RUNNING...", this.ress)
                    this.updateValidation()
                    return err
                })
        },
        validateEmail: function() {
            const regMail = /^([_a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,3})$/
            let res = regMail.test(this.email)
            let msg
            if (res === false)
                msg = "<span class='warning' style='color: red;'>Email is not valid yet.</span>"
            else msg = "<span class='valid' style='color: green;'>Done!"
            document.getElementById("email-status").innerHTML = msg
            return res
        },

        validatemobile: function() {
            let res = true
            let digitCount = 0
            for (let i = 0; i < this.mobile.length; i++) {
                if (
                    !Number.isInteger(Number(this.mobile)) ||
                    !(this.mobile[i] >= "0" && this.mobile[i] <= "9")
                ) {
                    res = false
                } else {
                    digitCount++
                }
            }
            let msg
            if (res == false)
                msg = "<span class='warning' style='color: red;'>Oops!!! Digits only.</span>"
            else if (digitCount != 10)
                msg = "<span class='warning' style='color: red;'>No!!! Just 10 digits</span>"
            else msg = "<span class='valid' style='color: green;'>Done!"
            document.getElementById("mobile-status").innerHTML = msg
            return res && digitCount == 10
        },

        getcity() {
            axios
                .get("/api/city/" + this.state)

                // .then((response) => {
                //   this.cities = [];
                //   response.data.forEach((city) => {
                //     this.cities.push({
                //       label: city.city_name,
                //       id: city.city_code,
                //     });
                //   });
                // });

                .then((response) => (this.cities = response.data))
        },
    },

    created() {
        axios
            .get("/api/state")

            //   .then((response) => {
            //     this.category = [];
            //     response.data.forEach((state) => {
            //       this.category.push({
            //         label: state.state_name,
            //         id: state.state_code,
            //       });
            //     });
            //   });

            .then((response) => (this.category = response.data))
    },
}
</script>
