<template>
    <div>
        <span>Confirm your email address with OTP to complete setting up your account.</span>
        <form @submit="verifyEmail">
            <div class="form-group">
                <!-- <label class="input-icon" for="email-input"><i class="fa fa-user"></i></label> -->
                <input
                    id="email-input"
                    type="email"
                    name="email"
                    placeholder="email"
                    class="styleinput"
                    v-model="email"
                    :readonly="emailReadonly"
                    :style="`background: ${emailReadonly ? '#ddd' : '#fff'}`"
                    required
                />
                <div class="status small" id="email-status"></div>
            </div>

            <div class="form-group" v-if="otpSent">
                <span
                    >We have sent an OTP to your email address. Please check your email and enter
                    here.</span
                >

                <!-- <label class="input-icon"><i class="fa fa-unlock-alt"></i></label> -->
                <input
                    type="text"
                    name="otp"
                    placeholder="Enter OTP"
                    class="styleinput"
                    v-model="otp"
                    required
                />
                <div class="status small" id="otp-status"></div>

                <button type="submit" class="my-2 registerbtn">
                    {{ verifyingOtp ? "Wait..." : "Confirm" }}
                </button>
            </div>

            <div class="" v-else>
                <button
                    type="button"
                    class="small registerbtn mr-2"
                    @click="editEmail"
                    v-if="showEditEmail"
                >
                    Edit
                </button>
                <button type="button" class="small registerbtn px-4" @click="sendOtp">
                    {{ sendingOtp ? "Wait..." : "Send OTP" }}
                </button>
            </div>
        </form>
    </div>
</template>

<script>
import Api from "../../apis/Api"
import request from '../../apis/request'
import { mapState, mapActions } from 'vuex'

export default {
    props: {},
    data() {
        return {
            email: "",
            otp: "",
            otpSent: false,
            emailReadonly: true,
            sendingOtp: false,
            showEditEmail: true,
            emailRegex: /^([_a-zA-Z0-9-]+)(\.[_a-zA-Z0-9-]+)*@([a-zA-Z0-9-]+\.)+([a-zA-Z]{2,3})$/,
            otpRegex: /^[0-9]+/,
            verifyingOtp: false,
        }
    },
    computed: {
        ...mapState('auth', {
            user: 'user'
        })  
    },
    mounted(){
        if(this.user){
            this.email = this.user.email
        }
    },
    methods: {
        ...mapActions('auth', {
            fetchUser: 'fetchUser'
        }),
        editEmail() {
            this.showEditEmail = false
            this.emailReadonly = false
        },
        notify(message, type = "success") {
            const toastConfig = {
                keepOnHover: true,
                iconPack: "fontawesome",
                theme: "toasted-primary",
                duration: 3000,
            }

            switch (type) {
                case "success":
                    toastConfig.icon = "check"
                    this.$toasted.success(message, toastConfig)
                    break
                case "error":
                    toastConfig.icon = "times"
                    this.$toasted.error(message, toastConfig)
                    break
            }
        },
        verifyEmail(e) {
            // verify the email with otp
            if (!this.otpSent || this.verifyingOtp) return
            e.preventDefault()
            if (this.otp.length !== 4 || !this.otpRegex.test(this.otp)) {
                return this.notify("OTP is not valid", "error")
            }
            this.verifyingOtp = true

            // send the original email for verification
            
            const credentials = {
                otp: this.otp,
                email: this.user.email,
                newemail: this.email,
            }
            console.log("otp for verification: ", credentials)
            request.post("/verify?complete_profile=true", credentials)
                .then((res) => {
                    // if required to sign in via 2factor auth - (additional otp after username/pw, then uncomment it to setup auth)
                    this.verifyingOtp = false
                    this.setupAuth(res)
                })
                .catch((res) => {
                    if (res.response.status === 401) {
                        this.ress = res.response.data.errors || {}
                    }
                    console.log("error in verifying otp", res, res.data)
                    this.verifyingOtp = false
                    return this.notify("OTP does not match", "error")
                })
        },
        async sendOtp() {
            if (this.sendingOtp) return
            this.sendingOtp = true
            if (!this.emailRegex.test(this.email)) {
                return this.notify("Please enter a valid email.", "error")
            }
            try {
                const res = await request.post("/send-otp", {
                    email: this.email,
                })
                console.log(res)
                this.otpSent = true
                this.emailReadonly = true
                this.notify("OTP sent successfully")
            } catch (err) {
                console.log("error in seding email otp", err)
                this.notify("error! Try again later.", "error")
            }
            this.sendingOtp = false
        },
        // updateUser() {
        //     const user = JSON.parse(localStorage.getItem("user"))
        //     if (user) {
        //         this.email = user.email
        //     }
        // },
        async setupAuth(res) {
            // console.log("otp verify res", res.data)
            // this.$root.$emit("login", true)
            // localStorage.setItem("auth", "true")
            // localStorage.setItem("user", JSON.stringify(res.data))
            await this.fetchUser()
            this.notify(res.data.message || "Profile completed!")

            location.href = "dashboard"
        },
    },
}
</script>

<style></style>
